var supervisor = null;
async function initProctor(jwtToken) {
  supervisor = new Supervisor({
    url: 'https://anthern.proctoring.app'
  });
  supervisor.init({
    provider: 'jwt',
    token: jwtToken
  }).then(function () {
    return supervisor.start();
  }).then(function () {
  }).catch(function (err) {
    alert(err.toString());
    location.href = '/';
  });
}

async function startProctor() {
  supervisor.on('start', function () {
  });
}

async function stopProctor() {
  supervisor.stop().then(function () {
    return supervisor.logout();
  }).then(function () {
  });
}