// var clientID = "osQFCwgd";
// var clientSecret = "PgaiDPDJQZ8CXpr";
// var testAttemptId = CryptoJS.lib.WordArray.random(5).toString();
// var hashedTestAttemptId = CryptoJS.SHA256(testAttemptId + clientSecret).toString();

//  const credentials = {
//     clientId: clientID,
//     testAttemptId: testAttemptId,
//     hashedTestAttemptId: hashedTestAttemptId
//   };
// const autoProctorTest = new AutoProctor(credentials);
/*
 * testAttemptId identifies each test attempt. This is a unique ID that your own platform generates
 */

// var testAttemptId = CryptoJS.lib.WordArray.random(5).toString();

/*
 * The SHA-256 HMAC of the testAttemptId with your SECRET_KEY
 * For more info: https://www.autoproctor.co/developers/docs#authentication
 */
// var hashedTestAttemptId = CryptoJS.SHA256(testAttemptId + clientSecret).toString();

//Configurations which you have to pass in SDK
// alert('calling js ')
var autoProctorTest =null;

async function getReport (clientID,clientSecret,testAttemptId, hashedTestAttemptId) {
  const credentials = {
    clientId: clientID,
    testAttemptId: testAttemptId,
    hashedTestAttemptId: hashedTestAttemptId
  };
  var apSettings = {
    trackingOptions: {
      audio: true,
      numHumans: true,
      tabSwitch: true,
      captureSwitchedTab: true,
      photosAtRandom: true,
      detectMultipleScreens: true,
      forceFullScreen: false,
      testTakerPhoto: true,
      numPhotosAtRandom: 10,
    },
    showHowToVideo: false,
  };
   autoProctorTest = new AutoProctor(credentials);
   await autoProctorTest.setup(apSettings);
   const reportOptions = getReportOptions();
   autoProctorTest.showReport(reportOptions);
}

const getReportOptions = () => {
  return {
      groupReportsIntoTabs: true,
      userDetails: {
          name: "a@b.com",
          email: "Test@123"
      }
  };
};

async function inittest(clientID,clientSecret,testAttemptId, hashedTestAttemptId, duration) {
  //tID=testAttemptId;
  // hTID=hashedTestAttemptId;

  const credentials = {
    clientId: clientID,
    testAttemptId: testAttemptId,
    hashedTestAttemptId: hashedTestAttemptId
  };
   autoProctorTest = new AutoProctor(credentials);

  var apSettings = {
    trackingOptions: {
      audio: true,
      numHumans: true,
      tabSwitch: true,
      captureSwitchedTab: true,
      photosAtRandom: true,
      detectMultipleScreens: true,
      forceFullScreen: false,
      testTakerPhoto: true,
      numPhotosAtRandom: 10,
      showCamPreview: true,
      testDuration:duration
    },
    showHowToVideo: false,
  };

  // autoProctorTest = await initAutoProctor(apSettings); 
  await autoProctorTest.setup(apSettings);
  autoProctorTest.start();
};
// function startproctor() {
//   autoProctorTest.start();
// }
function Terminateproctor() {
  autoProctorTest.stop();
}
function stopproctor() {
  autoProctorTest.stop();
  // const label = await fetch('https://www.autoproctor.co/tenant/tests/capture-test-submit');

  let testReportUrl = "https://www.autoproctor.co/tenant/" + clientID + "/test-attempts/id/" + tID + "/?hashedTestAttemptId=" + hTID;
  return testReportUrl;
  // alert(testReportUrl); 
  // const response= await fetch(testReportUrl);    
  // const response =  autoProctorTest.getEvidenceReport(
  //     clientID,
  //     testAttemptId,
  //     hashedTestAttemptId
  // );
  // const searchParams = new URLSearchParams({
  //     "hashedTestAttemptId": hashedTestAttemptId
  //   });

  //   let url = "https://www.autoproctor.co/tests/reports/test-attempt-label/" + testAttemptId + "?hide_navbar=true";      
  //   url.search = searchParams;

  //   await fetch(url);
  // const response =  autoProctorTest.getEvidenceReport(
  //     apSettings.clientId,
  //     apSettings.testAttemptId,
  //     apSettings.hashedTestAttemptId
  // );
}


